const SUFFIX = "$";

// auth sign in request
export const ERROR_AUTH_SIGN_IN_REGISTER_INVALID_REQUEST = `ERROR_AUTH_SIGN_IN_REGISTER_INVALID_REQUEST${SUFFIX}`;
export const ERROR_AUTH_SIGN_IN_REGISTER_EXPIRED = `ERROR_AUTH_SIGN_IN_REGISTER_EXPIRED${SUFFIX}`;

export const ERROR_AUTH_SIGN_IN_VERIFY_EXPIRED = `ERROR_AUTH_SIGN_IN_VERIFY_EXPIRED${SUFFIX}`;
export const ERROR_AUTH_SIGN_IN_VERIFY_INVALID_REQUEST = `ERROR_AUTH_SIGN_IN_VERIFY_INVALID_REQUEST${SUFFIX}`;
export const ERROR_AUTH_SIGN_IN_VERIFY_UNMATCHED_CODE = `ERROR_AUTH_SIGN_IN_VERIFY_UNMATCHED_CODE${SUFFIX}`;
export const ERROR_AUTH_SIGN_IN_VERIFY_INVALID_PERMISSION = `ERROR_AUTH_SIGN_IN_VERIFY_INVALID_PERMISSION${SUFFIX}`;

export const ERROR_AUTH_SIGN_IN_CONSUME_EXPIRED = `ERROR_AUTH_SIGN_IN_CONSUME_EXPIRED${SUFFIX}`;
export const ERROR_AUTH_SIGN_IN_CONSUME_INVALID_REQUEST = `ERROR_AUTH_SIGN_IN_CONSUME_INVALID_REQUEST${SUFFIX}`;

export const ERROR_REQEUST_AND_REGISTER_SIGN_IN_WITH_MCO = `ERROR_OAUTH_REQEUST_AND_REGISTER_SIGN_IN_WITH_MCO${SUFFIX}`;
export const ERROR_VERIFY_AND_CONSUME_SIGN_IN_WITH_MCO = `ERROR_OAUTH_VERIFY_AND_CONSUME_SIGN_IN_WITH_MCO${SUFFIX}`;

// auth connect request
export const ERROR_AUTH_CONNECT_VERIFY_MAXIMUM = `ERROR_AUTH_CONNECT_VERIFY_MAXIMUM${SUFFIX}`;
export const ERROR_AUTH_CONNECT_VERIFY_BAD_REQUEST = `ERROR_AUTH_CONNECT_VERIFY_BAD_REQUEST${SUFFIX}`;
export const ERROR_AUTH_CONNECT_VERIFY_UNMATCHED_CODE = `ERROR_AUTH_CONNECT_VERIFY_UNMATCHED_CODE${SUFFIX}`;
export const ERROR_AUTH_CONNECT_VERIFY_ALREADY_USED_ACCOUNT = `ERROR_AUTH_CONNECT_VERIFY_ALREADY_USED_ACCOUNT${SUFFIX}`;
export const ERROR_AUTH_CONNECT_VERIFY_INVALID_SCOPE = `ERROR_AUTH_CONNECT_VERIFY_INVALID_SCOPE${SUFFIX}`;

export const ERROR_AUTH_CONNECT_CONSUME_MAXIMUM = `ERROR_AUTH_CONNECT_CONSUME_MAXIMUM${SUFFIX}`;
export const ERROR_AUTH_CONNECT_CONSUME_BAD_REQUEST = `ERROR_AUTH_CONNECT_CONSUME_BAD_REQUEST${SUFFIX}`;
export const ERROR_AUTH_CONNECT_CONSUME_ALREADY_USED_ACCOUNT = `ERROR_AUTH_CONNECT_CONSUME_ALREADY_USED_ACCOUNT${SUFFIX}`;

// global error
export const ERROR_BAD_REQUEST = `ERROR_BAD_REQUEST${SUFFIX}`;
export const ERROR_UNAUTHORIZED = `ERROR_UNAUTHORIZED${SUFFIX}`;
export const ERROR_FORBIDDEN = `ERROR_FORBIDDEN${SUFFIX}`;
export const ERROR_NOT_FOUND = `ERROR_NOT_FOUND${SUFFIX}`;
export const ERROR_TOO_MANY_REQUESTS = `ERROR_TOO_MANY_REQUESTS${SUFFIX}`;
export const ERROR_INTERNAL_SERVER_ERROR = `ERROR_INTERNAL_SERVER_ERROR${SUFFIX}`;
export const ERROR_OOPS = `ERROR_OOPS${SUFFIX}`;
export const ERROR_UNKNOWN = `ERROR_UNKNOWN${SUFFIX}`;

// post
export const ERROR_INVALID_MENTION = `ERROR_INVALID_MENTION${SUFFIX}`;
