import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from "@reduxjs/toolkit";

import { Unwrap } from "~/api/types";
import type { TMessageEditorStateFromServer } from "~/modules/Posting/Editor/types";
import type { TMessage } from "../types/message";

export interface GenericMessageState<Message extends TMessage = TMessage> {
  messages: Array<Message> | null;
  hasNextMessage: boolean | null;
  hasPrevMessage: boolean | null;
  editorStateForEditing:
    | ({ id: string } & TMessageEditorStateFromServer)
    | null;
  replyMessage: Message | null;
  highlightId: string | null;
  initialScrollBottom: boolean;
  resetCounter: number;
  tempMessagesById: { [id: string]: Message[] };
  tempMessageAddedAt: number | null;
  isDeletingMessage: boolean;
  lastReadMessageId: string | null;
  isMessageUploading: boolean;
}

export const INITIAL_MESSAGE_STATE: GenericMessageState = {
  resetCounter: 0,
  messages: null,
  replyMessage: null,
  editorStateForEditing: null,
  hasPrevMessage: null,
  hasNextMessage: null,
  highlightId: null,
  initialScrollBottom: true,
  tempMessagesById: {},
  tempMessageAddedAt: null,
  isDeletingMessage: false,
  lastReadMessageId: null,
  isMessageUploading: false,
};

export const createMessageSlice = <
  Message extends TMessage,
  Reducers extends SliceCaseReducers<
    GenericMessageState<Message>
  > = SliceCaseReducers<GenericMessageState<Message>>,
>({
  name = "",
  initialState,
  reducers,
}: {
  name: string;
  initialState?: Partial<GenericMessageState<Message>>;
  reducers?: ValidateSliceCaseReducers<GenericMessageState<Message>, Reducers>;
}) => {
  const mergedInitialState: GenericMessageState<Message> = {
    ...(INITIAL_MESSAGE_STATE as GenericMessageState<Message>),
    ...initialState,
  };

  return createSlice({
    name,
    initialState: mergedInitialState,
    reducers: {
      resetToInitialState: () => ({
        ...mergedInitialState,
        highlightId: null,
      }),
      setMessages: (
        state: GenericMessageState,
        action: PayloadAction<GenericMessageState<Message>["messages"]>,
      ) => {
        state.messages = action.payload;
      },
      updateMessage: (
        state: GenericMessageState,
        action: PayloadAction<Unwrap<GenericMessageState<Message>["messages"]>>,
      ) => {
        if (!state.messages) return;

        const nextMessage = action.payload;
        state.messages =
          state.messages.map((message) =>
            message.id === nextMessage.id ? nextMessage : message,
          ) ?? null;
      },
      deleteMessage: (
        state: GenericMessageState,
        action: PayloadAction<string>,
      ) => {
        if (!state.messages) return;

        const nextMessageId = action.payload;
        state.messages =
          state.messages.filter((Message) => Message.id !== nextMessageId) ??
          null;
      },
      setHasNextMessage: (
        state: GenericMessageState,
        action: PayloadAction<GenericMessageState<Message>["hasNextMessage"]>,
      ) => {
        state.hasNextMessage = action.payload;
      },
      setHasPrevMessage: (
        state: GenericMessageState,
        action: PayloadAction<GenericMessageState<Message>["hasPrevMessage"]>,
      ) => {
        state.hasPrevMessage = action.payload;
      },
      setEditorStateForEditing: (
        state: GenericMessageState,
        action: PayloadAction<
          GenericMessageState<Message>["editorStateForEditing"]
        >,
      ) => {
        state.editorStateForEditing = action.payload;
      },
      setReplyMessage: (
        state: GenericMessageState,
        action: PayloadAction<GenericMessageState<Message>["replyMessage"]>,
      ) => {
        state.replyMessage = action.payload;
      },
      setHighlightId: (
        state: GenericMessageState,
        action: PayloadAction<GenericMessageState<Message>["highlightId"]>,
      ) => {
        state.highlightId = action.payload;
      },
      setInitialScrollBottom: (
        state: GenericMessageState,
        action: PayloadAction<
          GenericMessageState<Message>["initialScrollBottom"]
        >,
      ) => {
        state.initialScrollBottom = action.payload;
      },
      countUpResetCounter: (state) => {
        state.resetCounter += 1;
      },
      setTempMessages: (
        state: GenericMessageState,
        action: PayloadAction<{ id: string; messages: Message[] }>,
      ) => {
        const { id, messages } = action.payload;

        if (state.tempMessagesById[id]) {
          state.tempMessagesById[id] = messages;
        }
      },
      setTempMessagesById: (
        state: GenericMessageState,
        action: PayloadAction<GenericMessageState<Message>["tempMessagesById"]>,
      ) => {
        state.tempMessagesById = action.payload;
      },
      setTempMessagesAddedAt: (
        state: GenericMessageState,
        action: PayloadAction<
          GenericMessageState<Message>["tempMessageAddedAt"]
        >,
      ) => {
        state.tempMessageAddedAt = action.payload;
      },
      setTempMessageStateById: (
        state: GenericMessageState,
        action: PayloadAction<{
          tempMessagesId: keyof GenericMessageState<Message>["tempMessagesById"];
          messageId: Message["id"];
          nextState: Message["state"];
        }>,
      ) => {
        const { tempMessagesId, messageId, nextState } = action.payload;

        state.tempMessagesById[tempMessagesId].forEach((tempMessage) => {
          if (tempMessage.id === messageId) {
            tempMessage.state = nextState;
          }
        });
      },
      deleteTempMessageById: (
        state: GenericMessageState,
        action: PayloadAction<{
          tempMessagesId: keyof GenericMessageState<Message>["tempMessagesById"];
          messageId: Message["id"];
        }>,
      ) => {
        const { messageId, tempMessagesId } = action.payload;

        state.tempMessagesById[tempMessagesId] = state.tempMessagesById[
          tempMessagesId
        ].filter((tempMessage) => tempMessage.id !== messageId);
      },
      setIsDeletingMessage: (
        state: GenericMessageState,
        action: PayloadAction<
          GenericMessageState<Message>["isDeletingMessage"]
        >,
      ) => {
        state.isDeletingMessage = action.payload;
      },
      setLastReadMessageId: (
        state: GenericMessageState,
        action: PayloadAction<
          GenericMessageState<Message>["lastReadMessageId"]
        >,
      ) => {
        state.lastReadMessageId = action.payload;
      },
      setIsMessageUploading: (
        state: GenericMessageState,
        action: PayloadAction<
          GenericMessageState<Message>["isMessageUploading"]
        >,
      ) => {
        state.isMessageUploading = action.payload;
      },
      ...reducers,
    },
  });
};
