import { useEffect } from "react";

export function McoSigninCallbackCSR() {
  useEffect(() => {
    if (typeof window?.opener?.onOAuthCallback === "function") {
      window.opener.onOAuthCallback(window.location.href);
      window.close();
    }
  }, []);

  return null;
}
