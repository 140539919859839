/** 절대경로를 다루는 경우 활용합니다. */
export const EPathname = {
  // oauth
  OAuthAppleSigninSetup: "/oauth/apple/signin/setup",
  OAuthAppleSigninCallback: "/oauth/apple/signin/callback",
  OAuthAppleConnectSetup: "/oauth/apple/connect/setup",
  OAuthAppleConnectCallback: "/oauth/apple/connect/callback",
  OAuthGoogleSigninSetup: "/oauth/google/signin/setup",
  OAuthGoogleSigninCallback: "/oauth/google/signin/callback",
  OAuthGoogleConnectSetup: "/oauth/google/connect/setup",
  OAuthGoogleConnectCallback: "/oauth/google/connect/callback",
  OAuthMcoSigninCallback: "/oauth/mco/signin/callback",

  // google-connect
  GoogleConnectCreate: "/google-connect/create",

  // login
  Login: "/login",

  // invite
  Invite: "/invite",

  // find
  Find: "/find",

  // create
  Create: "/create",
} as const;

export type EPathname = typeof EPathname[keyof typeof EPathname];
