import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";

import "./styles/global.css";

import i18n from "~/i18n/i18n";
import App from "./App";
import { env } from "./env";
import { worker } from "./mocks/browser";
import { detectMobile } from "./_utils/mobile/detectMobile";

if (process.env.NODE_ENV === "production" && env.RELEASED_NAME) {
  if (env.RELEASED_NAME === "production") {
    /* 임시 주석
    window.dataLayer = window.dataLayer || [];
    const gtag = (...args: any) => {
      window.dataLayer.push(args);
    };
    gtag("js", new Date());
    gtag("config", process.env.REACT_APP_GA_KEY);*/
  }
  Sentry.init({
    dsn: "https://88302199a5bd48e4be344f79f004036a@o264640.ingest.sentry.io/6662469",
    integrations: [Sentry.browserTracingIntegration()],
    environment: env.RELEASED_NAME,
    release: env.RELEASED_NAME,
    tracesSampleRate: 1,
    beforeSend: (event) => {
      const handled = event.exception?.values?.some(
        (exception) => exception.mechanism?.handled,
      );

      return handled ? event : null;
    },
  });
}

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_ENABLE_MSW == "true"
) {
  worker.start({
    onUnhandledRequest: "bypass",
  });
}

const container = document.getElementById("root");
if (!container) {
  throw new Error("Failed to find the root element");
}

i18n;

const isMobile = detectMobile();
if (isMobile) {
  const htmlEl = document.querySelector("html") as HTMLElement | null;
  if (htmlEl) {
    htmlEl.classList.add("is-mobile");
  }
}

const root = createRoot(container);
root.render(
  <HelmetProvider>
    <App />
  </HelmetProvider>,
);
