import { Route, Routes, Navigate } from "react-router-dom";
import { RouteParamSubdomainWrapper } from "./routes/wrapper/RouteParamWrapper";
import { MobileEntranceCSR } from "~/_app/entrance/MobileEntranceCSR";
import { MobileExploreCSR } from "~/_app/[dao]/explore/MobileExploreCSR";
import { MobileBrowserNotSupported } from "~/modules/Router/Browser/MobileBrowserNotSupported";
import { lazy } from "react";
import { OAuthRoutes } from "~/_components/Routing/Routes/OAuthRoutes";

const Withdrawal = lazy(
  () => import(/*webpackChunkName: "routes-Withdrawal" */ "~/_app/withdrawal"),
);

export function MobileRoutes() {
  return (
    <Routes>
      <Route path="/@:subdomain">
        {/* 게이팅 존재시 처리 */}

        {/* 초대로 진입 */}
        <Route
          path="entrance"
          element={
            <RouteParamSubdomainWrapper>
              {() => <MobileEntranceCSR />}
            </RouteParamSubdomainWrapper>
          }
        />

        {/* 홈 둘러보기 */}
        <Route
          path="explore"
          element={
            <RouteParamSubdomainWrapper>
              {(params) => <MobileExploreCSR {...params} />}
            </RouteParamSubdomainWrapper>
          }
        />

        <Route
          index
          path="*"
          element={
            <RouteParamSubdomainWrapper>
              {({ subdomain }) => (
                <Navigate to={`/@${subdomain}/explore`} replace />
              )}
            </RouteParamSubdomainWrapper>
          }
        />
      </Route>

      <Route path="/oauth/*" element={<OAuthRoutes />} />
      <Route path="/withdrawal" element={<Withdrawal />} />
      <Route path="*" index element={<MobileBrowserNotSupported />} />
    </Routes>
  );
}
